<template>
  <div v-if="$isLoose && !(Item && getSubCategory(Item.SubCategoryId).item_label)" class="button-select">
    <Button77 block :variant="btnVariant" tabindex @click.stop="open">
      {{ $t(`add-${$stoneLabel}`, `Add ${$stoneLabel}`) }}
    </Button77>

    <Popup v-if="addStoneModal" v-model="addStoneModal" container-class="text-center py-8 min-w-[300px]">
      <template v-if="!isGroupedShape">
        <p class="h2 mb-6">{{ $t('what-would-you-like-to-do', 'What would you like to do?') }}</p>

        <template v-if="!$isPair">
          <Button77
            :to="setDiamond(1)"
            data-cy="add-diamond-to-ring"
            variant="outlined"
            class="w-4/6 mb-6"
            @click="sendEvent"
            >{{ $t('add-to-ring', 'Add to Ring') }}
          </Button77>
          <Button77
            :to="setDiamond(4)"
            data-cy="add-diamond-to-pendant"
            variant="outlined"
            class="w-4/6 mb-6"
            @click="sendEvent"
          >
            {{ $t('add-to-pendant', 'Add to Pendant') }}
          </Button77>
        </template>
        <template v-else>
          <Button77 :to="setDiamond(5)" data-cy="add-diamond-to-studs" variant="outlined" class="w-4/6 mb-6"
            >{{ $t('add-to-earrings', 'Add to Earrings') }}
          </Button77>
        </template>
        <Button77
          variant="link"
          class="w-4/6 uppercase"
          tabindex
          data-cy="add-diamond-to-bag"
          @click="
            () => {
              addToShoppingBag(opened)
              addStoneModal = false
            }
          "
        >
          {{ $t('add-to-basket', 'Add to Basket') }}
        </Button77>
      </template>

      <template v-else>
        <p class="h2 mb-6">{{ $t('create-bespoke-design', 'Create a bespoke design?') }}</p>
        <p class="body-14-aa mb-2">
          {{
            $t(
              'create-bespoke-design-description',
              'Your selected gem requires a bespoke setting. If you’d like to create a jewel, please send your enquiry via our bespoke service, detailing your preferences.',
            )
          }}
        </p>
        <Button77 variant="outlined" class="w-4/6 mb-6" data-cy="add-diamond-to-ring" @click="openBespoke(opened[0])">
          {{ $t('send-an-enquiry', 'Send An Enquiry') }}
        </Button77>
        <p class="body-14-aa mb-1">{{ $t('purchase-loose-gems', 'I’m looking to purchase loose gems only today.') }}</p>
        <Button77
          variant="link"
          class="w-4/6"
          tabindex
          data-cy="add-diamond-to-bag"
          @click="
            () => {
              addToShoppingBag(opened)
              addStoneModal = false
            }
          "
        >
          {{ $t('proceed-to-checkout', 'Proceed to checkout') }}
        </Button77>
      </template>
    </Popup>
  </div>
  <Button77
    v-else
    @click="() => $router.push(setDiamond($route.query.category))"
    data-gtm-tracking="diamond-selected"
    data-cy="add-stone-to-selected-item"
    block
    small
    :variant="btnVariant"
  >
    <template v-if="Item && getSubCategory(Item.SubCategoryId).item_label">
      {{ $t('add-to', 'Add to') }}
      {{ $t(getSubCategory(Item.SubCategoryId).item_label || '') }}
    </template>
    <template v-else>
      {{ $t('select', 'Select') }}
    </template>
  </Button77>
</template>

<script setup lang="ts">
import { ga_event_quick_init } from '~/utils/shop/ga'
import { getSubCategory } from '~/utils/shop/subCategories'
import { isGroupedShape as _isGroupedShape, openBespoke } from '~/utils/shop/general'

import { useProductsStore } from '~/store/productsStore'
import { SHOP_ROUTES_NAMES } from '~/constants'

import Button77 from '~/components/ui/Button77.vue'
import Popup from '~/components/ui/Popup.vue'

const { $isLoose, $stoneLabel, $isPair } = useShop()
const { item } = storeToRefs(useProductsStore())
const { addToShoppingBag } = useDiamonds()

const { getShopRouteByLegacyName } = useShopRoute()
const { translateShopAspRouteByQueryNew } = useAspRoutes()
const route = useRoute()

const props = defineProps({
  opened: {
    type: Array,
    default: null,
  },
  btnVariant: {
    type: String,
    default: 'default',
  },
})

// refs
const addStoneModal = ref(false)

// computeds
const Item = computed(() => item.value?.ItemSettings)
const isGroupedShape = computed(() => {
  return _isGroupedShape(props.opened[0].ShapeId)
})

// methods
const open = () => {
  addStoneModal.value = true
  sendEvent()
}

const sendEvent = () => {
  if (route.query.quickShipping == 1) {
    ga_event_quick_init('quick x non quick', 'proceed to step 2 with quick stone')
  } else {
    ga_event_quick_init('quick x non quick', 'proceed to step 2 without quick stone')
  }
}

const setDiamond = (category) => {
  if (!props.opened) return {}

  const codes = props.opened.map((d) => d.Code).join(',')
  let fancyCodeQuery = route.query['stoneType,diamondType,gemType,fancyColorId']
  if (props.opened[0].FancyColorId) {
    // check if fancyColorId is included in query
    if (fancyCodeQuery && !fancyCodeQuery.includes(props.opened[0].FancyColorId)) {
      fancyCodeQuery = `${route.query.stoneType || 1},${'' || route.query.diamondType},${route.query.gemType || ''},${props.opened[0].FancyColorId || ''}`
    }
  }

  if (route.query.item > 0) {
    return getShopRouteByLegacyName({
      name: SHOP_ROUTES_NAMES.ITEM_FINISH,
      query: {
        // subcategory: Item.value.SubCategoryId,
        diamond: codes,
        // item: Item.value.Id,
        shape: props.opened[0].ShapeId,
        fancyColorId: props.opened[0].FancyColorId,
        // 'stoneType,diamondType,gemType,fancyColorId': fancyCodeQuery,
      },
    })
  } else {
    return translateShopAspRouteByQueryNew(
      {
        fullPath: route.fullPath,
        query: {
          ...route.query,

          category,
          step: null, // delete step to not mess the shop routing, not needed in this case
          subcategories: null,
          'start-diamond': true,
          shape: props.opened[0].ShapeId,
          shapes: [props.opened[0].ShapeId],
          diamond: codes,
          opened: null, // once diamond/diamonds are selected we do not need to keep opened, opened is only for diamond details
          fancyColorId: props.opened[0].FancyColorId,
          'stoneType,diamondType,gemType,fancyColorId': fancyCodeQuery,
        },
      },
      true,
    )
  }
}
</script>

<style scoped lang="scss">
.button-select {
  display: inline-block;
}
</style>
